import { createGlobalStyle, css } from "styled-components"
import respond from "../abstracts/mediaqueries"

export default createGlobalStyle`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility; 
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow-x: hidden;
  font-size: 10px;
  scroll-behavior: smooth;

  ${respond(
    "4k-tv",
    css`
      font-size: 20px;
    `,
  )}
}

body {
  font-family: var(--body-font);
  color: var(--black);
  font-size: var(--basic-font-size);
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 1.5;
  background-color: var(--body-background);
  overflow-x: hidden;
  padding: 0;
  scroll-

  ${respond(
    "macbook-air",
    css`
      font-size: 1.8rem;
    `,
  )}
      ${respond(
        "ipad-pro-11-land",
        css`
          font-size: 1.6rem;
        `,
      )}

${respond(
  "ipad-port",
  css`
    line-height: 1.3;
  `,
)}

  ${respond(
    "phone-land",
    css`
      font-size: var(--mobile-font-size);
      overflow-wrap: break-word;
    `,
  )}
  ${respond(
    "iphone-5",
    css`
      font-size: var(--mobile-font-size);
      overflow-wrap: anywhere;
    `,
  )}
}

h1 {
  font-size: var(--big-title);
  ${respond(
    "macbook-air",
    css`
      font-size: 3.8rem;
    `,
  )}
      ${respond(
        "ipad-pro-11-land",
        css`
          font-size: 3.2rem;
        `,
      )}
      ${respond(
        "ipad-port",
        css`
          font-size: 2.8rem;
        `,
      )}
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--title-font);
  font-weight: bold;
  color: var(--black);

  ${respond(
    "tab-land",
    css`
      font-size: 3.5rem;
    `,
  )}
  ${respond(
    "phone-land",
    css`
      font-size: var(--mobile-title-font-size);
    `,
  )}
}

h2,
h3,
h4,
h5,
h6 {
  font-size: var(--title-font-size);

  ${respond(
    "macbook-air",
    css`
      font-size: 2.4rem;
    `,
  )}
   ${respond(
     "ipad-pro-11-land",
     css`
       font-size: 2.2rem;
     `,
   )}
      ${respond(
        "ipad-port",
        css`
          font-size: 2rem;
        `,
      )}

  ${respond(
    "phone-port",
    css`
      font-size: 2rem;
    `,
  )}
}

p {
  letter-spacing: 0.1rem;
  line-height: 2.8rem;
   ${respond(
     "ipad-port",
     css`
       line-height: 2.4rem;
     `,
   )}
}

/* section {
  padding-bottom: 8rem;
  ${respond(
    "ipad-11-pro-land",
    css`
      padding-bottom: 6rem;
    `,
  )}

   ${respond(
     "ipad-port",
     css`
       padding-bottom: 4rem;
     `,
   )}
} */

section{
   padding-bottom: 8rem;
   ${respond(
     "ipad-port",
     css`
       padding-bottom: 6rem;
     `,
   )}
   ${respond(
     "ipad-port",
     css`
       padding-bottom: 4rem;
     `,
   )}
}

a {
  color: var(--black);
  text-decoration: none;
}


strong {
  font-weight: 700;
}

i {
  font-style: italic;
}

button {
  letter-spacing: inherit;
}

.container {
  ${respond(
    "phone-port",
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `,
  )}
  ${respond(
    "iphone-5",
    css`
      padding: 0;
      max-width: 95vw;
    `,
  )}
}

iframe {
  border: none;
}

strong {
  font-weight: 700 !important;
}
`
