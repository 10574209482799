import { createGlobalStyle } from "styled-components"

export default createGlobalStyle/*css*/`
  :root {
      // COLORS
    --body-background: #ffffff;
    --color-primary: #0d4da2;
    --color-primary-dark: #007BFF;
    --color-primary-light: #F3F9FF;
    --color-secondary: #f5821f;
    --preheader-background: #0d4da2;
;   --black: #000000;
    --white: #ffffff;
    --navbar-active-color: #ED1C24;
    --grey: #585858;
    --grey300: #D0D0D0;

    //FONT FAMILY
    --title-font: "Anek Latin", sans-serif;
    --alt-font: "Oswald", sans-serif;
    --body-font: "Anek Latin", sans-serif;
    --bold: 700;

    // FONT-SIZES
    --basic-font-size: 2rem;
    --mobile-font-size: 1.5rem;

    --big-title: 4.8rem;
    --title-font-size: 3rem;
    --mobile-title-font-size: 3rem;
    --small-title: 2.2rem;
    --big-text: 2rem;

    //SECTIONS
    --section-width: 70%;
    --laptop-container: 80%;

    --gutter: 3rem;
    --big-gutter: 4rem;
    --section-gutter: 6rem;

    //SHADOWS
    --cards-shadow: 3px 3px 22px -12px rgba(0, 0, 0, 0.42)
  }
`
