// gatsby-ssr.js
import React from "react"
import "./src/scss/index.scss"
import GlobalStyles from "./src/styles/global-styles"
// import LocationContext from "./src/context/LocationContext"
// import { parseParams } from "./src/helpers/helpers";
import { ContextProvider } from "./src/context/AppContext"

export const wrapRootElement = ({ element }) => {
  return <ContextProvider>{element}</ContextProvider>
}

export const wrapPageElement = ({ element }) => {
  // const params = parseParams(element.props.location.search);
  return (
    <>
      <GlobalStyles />
      {element}
    </>
  )
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (location.hash === "") {
    window.scrollTo(0, 0)
  } else {
    const item = document.querySelector(location.hash)
    if (item) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          const navbar = document.querySelector(".navbar")
          const navbarHeight = navbar.offsetHeight
          const navbarStyle = getComputedStyle(navbar)
          const navbarPaddingTop = parseInt(navbarStyle.paddingTop, 10)
          const navbarPaddingBottom = parseInt(navbarStyle.paddingBottom, 10)
          const totalNavbarHeight =
            navbarHeight + navbarPaddingTop + navbarPaddingBottom

          console.log("Navbar height: ", navbarHeight)
          console.log("Navbar padding top: ", navbarPaddingTop)
          console.log("Navbar padding bottom: ", navbarPaddingBottom)
          console.log("Total navbar height: ", totalNavbarHeight)

          const itemPosition = item.getBoundingClientRect().top + window.scrollY
          console.log("Item position before scroll: ", itemPosition)

          window.scrollTo({
            top: itemPosition - totalNavbarHeight,
            behavior: "smooth",
          })

          console.log("ScrollY after scroll: ", window.scrollY)
        })
      }, 100)
    }
  }

  return false
}
